import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

Rails.start();
Turbolinks.start();
ActiveStorage.start();

import dt from "datatables.net";

document.addEventListener("turbolinks:load", () => {
    dt(window, $);
});

//import flatpickr from "flatpickr";
//require("flatpickr/dist/themes/material_blue.css");

//import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'
//require('flatpickr/dist/plugins/monthSelect/style.css')


import "stylesheets/application";
